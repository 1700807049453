<template>
  <div>
    <headers />
    <div class="mb row" style="padding:15px;padding-top: 90px !important;">
      <div class="col-12">
        <div class="tab-box">
         
        </div>
      </div>

      <div class="tab-content" style="padding: 15px;background:#fff">
        
        <div class="embed-responsive-16by9 tab-pane tc9 active" id="tc3">
          <div class="col-12">
            <div class="card-box" v-if="showTable">
              <list-Corp-neet-recommend
                :viewParams="viewParamsOffice"
                :area_name_jp="area_name_jp"
                :area_name_en="area_name_en"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import Headers from "@/components/Headers.vue";
import "vue3-carousel/dist/carousel.css";
import ListCorpNeetRecommend from "@/components/ListCorpNeetRecommend.vue";

export default {
  name: "CorpDetail",
  components: {
    Headers,
    ListCorpNeetRecommend,
  },
  data() {
    return {
      viewParamsOffice: {
        name: "corp_offices",
        apiUrl: "",
        paramKey: "ID",
        randIndex: 0,
      },
      corpNumber:'',
      showTable:false
    }
  },
  mounted() {
    const route = useRoute()
    if(route.query.corpNumber){
      this.corpNumber = route.query.corpNumber
      this.setCorpNumber()
    }
  },
  methods:{
      setCorpNumber(){
        this.viewParamsOffice.apiUrl = "/v1/corps_neet_recommend?corpNumber=" + this.corpNumber
        this.showTable = true
      }
  }
};
</script>
<style lang="scss">
.rent-list .table-striped tr td:nth-child(1) {
  text-decoration: underline;
}
.btn-back {
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 255;
}

.btn-back-fixed {
  margin-right: 35px;
  margin-top: -65px;
}
.build-article .card-box {
  max-height: 1460px;
  overflow-y: auto;
}

.build-article .row > div {
  display: flex;
  flex-direction: column;
}

.build-article .card-box {
  flex: 1;
}

// rader-chart
.build-article .embed-responsive-1by1 {
  max-height: 550px;
}

.build-article .row .btn-toolbar {
  flex-direction: row;
}

.other-comments-container {
  position: relative;
}

.other-item {
  text-align: left;
  display: block;
  flex: 1;
  height: 230px;
  overflow-y: auto;
  padding: 0.5rem 1rem;
  word-break: break-all;
}

.other-control {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1.5rem 0.25rem;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.15s;
}

.other-control-prev {
  left: -1.5rem;
}

.other-control-next {
  right: -1.5rem;
}
.piclist .carousel {
  height: 640px;
  width: 90%;
}
.carousel__prev,
.carousel__next {
  background: #012a2d;
}
.carousel__pagination-button--active {
  color: #012a2d;
  background: #012a2d;
}
.carousel__slide {
  margin: 0 auto;
  width: 100% !important;
}
.table-borderless tr {
  border-bottom: 1px solid #ddd;
}
.carousel__slide img {
  max-width: 100%;
  max-height: 100%;
}
.responsive-table-plugin tbody th {
  width: 25%;
  font-weight: bolder !important;
}
.market_url {
  width: 100%;
  height: 800px;
  border: 0;
}
.embed-responsive {
  min-height: 540px;
}
.embed-responsive-item {
  width: 100%;
  height: 540px;
  border: 0;
}
.popup-modal {
  display: block;
}
.popup-bg {
  background: rgba(0, 0, 0, 0.25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 9;
}

.popup {
  background: #fff;
  border-radius: 0.15rem;
  box-shadow: 0 0.15rem 0.5rem rgba(0, 0, 0, 0.25);
  left: 50% !important;
  max-height: 85vh;
  min-height: 750px;
  width: 850px;
  position: fixed;

  transform: translateX(-50%) !important;
  z-index: 16;
}

.popup iframe {
  width: 100%;
}

.help {
  position: absolute;
  top: 5px;
  right: 20px;
  display: inline-block;
}
.help-box {
  margin-top: 10%;
  width: 80%;
}
.tab-box {
  position: relative;
}
.hide {
  display: none;
}
.google_maps {
  height: 540px;
}
</style>